import { useEffect, useRef, useState } from 'react';
import { Modal, Button, Input, Grid } from 'antd';
import dayjs from 'dayjs';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { BalanceData } from '../../../../@types/Balance';
import { LeaveRequestPost } from '../../../../@types/Request';
import { LeaveOptions } from '../../../../@types/FetchOptions';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import useTableContext from '../../../table/provider/TableContext';
import { createLeave } from '../../../../api-requests/Leave';
import { stringifySorting } from '../../../../utils/sortingParser';
import WarningModal from '../../../../components/modal/WarningModal';
import { LeaveColors } from '../../../../../shared/colors';
import LoomSelect from '../../../../components/StyledComponents/LoomSelect';
import LoomDatePicker from '../../../../components/StyledComponents/LoomDatePicker';
import LeaveFormStats from './LeaveFormStats';
import { createLeaveSchema, initLeaveRequest } from '../../../../utils/FormValidation/LeaveFormValidation';
import { useBalance } from '../../provider/useBalance';
import { MyLeaveContext, useLeaveContext } from '../../provider/GenericLeaveProvider';

const { TextArea } = Input;
const { useBreakpoint } = Grid
type Props = {
  balance: BalanceData[];
  values: LeaveRequestPost;
  setFieldValue: (key: string, value: unknown) => void;
}
type CalendarModalFormProps = {
  isOpen?: boolean;
  data: LeaveRequestPost;
  setData: (value: LeaveRequestPost) => void;
  setIsOpen: (value: boolean) => void;
  fetchData: (value: LeaveOptions) => void;
};
type PartsProps = { values: LeaveRequestPost; setFieldValue: (key: string, value: unknown) => void }

const CalendarModalForm = ({
  isOpen,
  data,
  setData,
  setIsOpen,
  fetchData,
}: CalendarModalFormProps) => {
  const { xs } = useBreakpoint();
  const { openNotification } = useNotifications();
  const { balance } = useBalance();
  const { sort, pageSize, from, to, status } = useTableContext();
  const [workableDays, setWorkableDays] = useState<number>(1);
  const [showNavBlock, setShowNavBlock] = useState(false);
  const [creating, setCreating] = useState(false);
  const { fetchLeavesFunc } = useLeaveContext(MyLeaveContext)
  const formRef = useRef<FormikProps<LeaveRequestPost> | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (balance && isOpen) {
      const defaultLeaveType = balance.find((bal) => bal.Policy.TypeOfLeave.Id === 1);
      if (defaultLeaveType)
        setData({ ...data, LeaveType: defaultLeaveType });
    }
  }, [balance, isOpen]);

  const submitStep = async (values: LeaveRequestPost) => {
    try {
      const formattedValues = {
        ...values,
        leaveBalanceId: values.LeaveType?.Id,
        Start: moment(values.Start).format('YYYY-MM-DDTHH:mm:ss'),
        End: moment(values.End).format('YYYY-MM-DDTHH:mm:ss'),
      };
      setCreating(true);
      await createLeave(formattedValues);
      openNotification('topRight', {
        title: 'Leave',
        description: 'Leave created successfully. An operator will approve your leave.',
        type: 'Success',
      });
      fetchLeavesFunc({})
      navigate('/apps/leave/list');
    } catch (err) {
      handleRequestError(err);
    } finally {
      formRef.current?.resetForm();
      setCreating(false);

      setIsOpen(false);
    }
  };

  const handleRequestError = (err: unknown) => {
    if (err instanceof AxiosError) {
      openNotification('topRight', {
        title: 'Leave',
        description: err?.response?.data?.error,
        type: 'Danger',
      });
    }
    fetchData({
      sorting: stringifySorting(sort),
      pageIndex: 0,
      pageSize: pageSize?.value,
      status: status,
      from: from,
      to: to,
    });
  };

  const handleCloseModal = () => {
    if (formRef.current?.dirty) {
      setShowNavBlock(true);
    } else {
      resetFormAndCloseModal();
    }
  };

  const resetFormAndCloseModal = () => {
    setData(initLeaveRequest);
    formRef.current?.resetForm();
    setIsOpen(false);
    setShowNavBlock(false);
  };

  return (
    <div data-testid="calendarModal">
      <WarningModal
        title="Unsaved Changes"
        isOpen={showNavBlock}
        setIsOpen={setShowNavBlock}
        footerButtons={[
          <Button key="leave" onClick={resetFormAndCloseModal}>
            Leave
          </Button>,
          <Button
            key="return"
            onClick={() => setShowNavBlock(false)}
            style={{ background: LeaveColors[0].value, color: 'white' }}
          >
            Return to form
          </Button>,
        ]}
        mainText="Are you sure you want to leave the page without submitting the form? Your changes will not be saved."
      />

      <Modal
        width={800}
        style={{ top: xs ? 0 : '' }}
        open={isOpen}
        onCancel={handleCloseModal}
        title="Add new Leave"
        footer={[
          <Button
            data-testid="submitLeave"
            type="primary"
            key="submit"
            size="large"
            disabled={creating}
            htmlType="submit"
            form="FormLeave"
          >
            <span className="indicator-label">Submit</span>
          </Button>,
        ]}
      >
        <Formik
          innerRef={(formikProps) => (formRef.current = formikProps)}
          validationSchema={createLeaveSchema}
          initialValues={data}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await submitStep(values);
            resetForm();
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} id="FormLeave" className="w-full">
              <div className="flex flex-col gap-6 md:p-4">
                <LeaveTypeDropdown
                  balance={balance}
                  values={values}
                  setFieldValue={setFieldValue}
                />

                <DatePickers
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <TextAreaField
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <LeaveFormStats
                  startDate={values.Start as string}
                  endDate={values.End as string}
                  leaveBalance={values.LeaveType}
                  values={values}
                />
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

// Separate component for the leave type dropdown
const LeaveTypeDropdown = ({ balance, values, setFieldValue }: Props) => (
  <LoomSelect<number | BalanceData>
    label="Leave Type"
    name="LeaveType"
    placeholder="Balance"
    valueFunc={values.LeaveType?.Id || balance?.find((bal) => bal.Policy.TypeOfLeave.Id === 1)?.Id}
    onChange={(newValue) =>
      setFieldValue('LeaveType', balance.find((bal) => bal.Id === newValue))
    }
    options={balance?.map((d) => ({
      label: `${d.Policy.TypeOfLeave.Name} ${d.Year} (Remaining days: ${d.RemainingDays})`,
      value: d.Id,
    }))}
  />
);


// Separate component for start and end date pickers
const DatePickers = ({ values, setFieldValue }: PartsProps) => (
  <div className="flex gap-4 w-full">
    <div className="flex-1">
      <LoomDatePicker
        name="Start"
        label="Start Date"
        minDate={dayjs()}
        onChange={(date) => {
          setFieldValue('Start', date.toDate());
          if (dayjs(date).isAfter(values.End)) {
            setFieldValue('End', date.toDate());
          }
        }}
        format="DD MMM YYYY"
        value={dayjs(values.Start)}
      />
    </div>
    <div className="flex-1">
      <LoomDatePicker
        name="End"
        label="End Date"
        minDate={values.Start ? dayjs(values.Start) : dayjs()}
        onChange={(date) => setFieldValue('End', date.toDate())}
        format="DD MMM YYYY"
        value={dayjs(values.End)}
      />
      <ErrorMessage name="End" component="div" className="text-red-500 mt-1" />
    </div>
  </div>
);


// Separate component for the comments section
const TextAreaField = ({ values, setFieldValue }: PartsProps) => (
  <div data-testid="commentInput">
    <label className="form-label mb-3">Comments</label>
    <TextArea
      rows={2}
      size="large"
      value={values.Description}
      placeholder="Comments"
      onChange={(e) => setFieldValue('Description', e.target.value)}
    />
  </div>
);

export default CalendarModalForm;
